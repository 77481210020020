import {api} from 'services/api';

export const getRoomsThunk = async (id, {rejectWithValue, dispatch, getState}) => {

	async function sendRequest(params) {
		const cfg = {
			jsonrpc: '2.0',
			method: 'listSmartHomeRealEstates',
		}

		const { data } = await api.post('/mcrm', { ...cfg, params });

		const next_page_token = data?.result?.metadata?.next_page_token;

		if (next_page_token) {
			const extraData = await sendRequest({ ...params, page_token: next_page_token })

			if (extraData?.result?.data) {
				data.result.data = data.result.data.concat(extraData?.result?.data);
			}
		}

		return data;
	}

	try {
		return await sendRequest({
			filter: {
				field: 'building_id',
				operator: '=',
				value: id,
			},
		});
	} catch (error) {
		console.log(error);
		return rejectWithValue(error.message);
	}
};
